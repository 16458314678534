import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '../components/image';
import PressQuotes from '../components/press-quotes';

import './about.scss';

const AboutPage = () => {
  const { t } = useTranslation('About');

  return (
    <Layout>
      <SEO title={t('title')} />
      <div className="st-p-landing__simple-hero">
        <div className="container">
          <h1 className="st-p-landing__hero-title">{t('title')}</h1>
        </div>
      </div>
      <section className="st-p-landing__section">
        <div className="container">
          <p className="subtitle is-4">{t('mission.paragraph')}</p>
        </div>
      </section>

      <div className="st-p-landing__section is-grey">
        <div className="container">
          <PressQuotes />
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
